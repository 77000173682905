import { SVGProps } from 'react';
const XIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || 24}
    height={props?.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 7L7 17M7 7L17 17"
      stroke={props?.stroke}
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default XIcon;
