import { useRouter } from "@/shared/utils/hooks/useRouter";
import { useEffect, useState } from "react";
import Layout from "./Layout";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react-pro";
import { ITransaction } from "@/shared/model/transaction.model";
import axios from '../../../shared/config/axios-interceptor'
import { RootState } from "@/reducers";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { ResponseStatusTransaction, mapResponseStatusToColorTransaction, mapResponseStatusTransaction } from "@/shared/enumeration/ResponseStatus";

const HistoryWithdraw = () => {
    const { navigate } = useRouter();
    const { user } = useSelector((state: RootState) => state.authentication);

    const [data, setData] = useState<ITransaction[] | []>([])

    const getData = async () => {
        try {
            const response = await axios.get(
                '/transaction?type=2&sortOrder=DESC&sortBy=id&limit=20&userId=' + user?.id,
            )
            setData(response.data.data)
        } catch (error) {
            setData([])
            console.error('Error:', error)
        }
    }


    useEffect(() => {
        getData();
    }, [])


    return <Layout >
        <div className="box-history-order">


            <div className="box-header d-flex justify-content-center align-items-center">

                <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                <h5 className="text-white text-center mb-0 mt-0">Lịch sử rút tiền</h5>

            </div>


            <div className="box-history">

                {

                    data.map((item, index) => {
                        return <div className="item" key={index}>
                            <div className="d-flex justify-content-between">
                                <div className="text-white">Rút</div>
                                <div style={{fontSize: 14, marginTop: 4, color: "#fff"}}>{dayjs(item.createdDate).format('DD/MM/YYYY HH:mm')}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div style={{color: "#ffca2d"}}>{new Intl.NumberFormat('vi-VN').format(item?.money || 0)}</div>
                                 <div style={{fontSize: 14}} className={'text-' + mapResponseStatusToColorTransaction[item?.status]}>{mapResponseStatusTransaction[item?.status]}</div>
                            </div>
                        </div>
                    })

                }

            </div>


        </div>
    </Layout>
}

export default HistoryWithdraw;