import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useSelector } from 'react-redux'
import Layout from './Layout'
import avatar from '../../../assets/img/avatar.gif'

const AccountInfor = () => {
  const { navigate } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication)
  return (
    <Layout>
      <div className="box-history-order">
        <div className="box-header d-flex justify-content-center align-items-center">
          <ArrowLeft
            style={{ position: 'absolute', left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="text-white text-center mb-0 mt-0">Hồ sơ của tôi</h5>
        </div>

        <div className="box-header d-flex align-items-center gap-2 py-3 px-3">
          <img
            width={50}
            height={50}
            style={{ borderRadius: '50%' }}
            src={avatar}
          />
          <div className="content">
            <div style={{ fontSize: 15 }}>{user?.username}</div>
            <span style={{ fontSize: 15 }}>ID: {user?.id}</span>
          </div>
        </div>

        <div className="px-3 mt-4">
          <div className='mb-4'>
            <div style={{ fontSize: 14 }}>Tên đầy đủ: <strong>{user?.username}</strong></div>
          </div>

          <div className='mb-4'>
            <div style={{ fontSize: 14 }}>Số điện thoại <strong>{user?.phone}</strong></div>
          </div>

          {user?.bankName && (
            <div className="bank-box-bank mt-4">
              <div>Thông tin ngân hàng</div>
              <div>{user?.bank?.code}</div>
              <div>{user?.cardNumber}</div>
              <div>{user?.cardName}</div>
            </div>
          )}

        </div>
      </div>
    </Layout>
  )
}
export default AccountInfor
