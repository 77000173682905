import { SVGProps } from 'react';
const Edit02Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || 16}
    height={props?.height || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <g clipPath="url(#clip0_726_2496)">
      <path
        d="M12.0003 6.66622L9.33368 3.99955M1.66699 14.3329L3.92324 14.0822C4.1989 14.0516 4.33673 14.0362 4.46556 13.9945C4.57985 13.9575 4.68862 13.9053 4.78892 13.8391C4.90196 13.7646 5.00002 13.6665 5.19614 13.4704L14.0003 4.66622C14.7367 3.92984 14.7367 2.73593 14.0003 1.99955C13.264 1.26317 12.0701 1.26317 11.3337 1.99955L2.52948 10.8037C2.33336 10.9999 2.2353 11.0979 2.16075 11.211C2.09461 11.3113 2.04234 11.42 2.00533 11.5343C1.96363 11.6632 1.94831 11.801 1.91769 12.0766L1.66699 14.3329Z"
        stroke={props?.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
    <defs>
      <clipPath id="clip0_726_2496">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Edit02Icon;
