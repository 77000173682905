import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import AddBank from './AddBank';
import Withdraw from './Withdraw';
import HistoryOrder from './HistoryOrder';
import HistoryWithdraw from './HistoryWithdraw';
import HistoryDeposit from './HistoryDeposit';
import ChangePassword from './ChangePassword';
import Message from './Message';
import Home from './Home';
import Account from './Account';
import History from './History';
import Info from './Info';
import Game from './Game';
import Withdraw_1 from './Withdraw _1';
import AccountInfor from './AccountInfor';
import DauTu from './DauTu';
import ThongBao from './ThongBao';
import ChinhSachBaoMat from './ChinhSachBaoMat';
import ChinhSach from './ChinhSach';
import VeChungToi from './VeChungToi';
import DieuKhoanSuDung from './DieuKhoanSuDung';
import XacMinh from './XacMinh';
import ChangePasswordWithdraw from './ChangePasswordDraw';

// const Home = React.lazy(() => import('./Home'));
// const Account = React.lazy(() => import('./Account'));
// const History = React.lazy(() => import('./History'));

const WebManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/account',
    element: <Account />,
  },

  {
    path: '/history',
    element: <History />,
  },
  {
    path: '/add-bank',
    element: <AddBank />,
  },
  {
    path: '/withdraw',
    element: <Withdraw />,
  },
  {
    path: '/withdraw1',
    element: <Withdraw_1 />, // Nạp tiền
  },
  {
    path: '/cskh',
    element: <Withdraw />,
  },
  {
    path: '/history-order',
    element: <HistoryOrder />,
  },
  {
    path: '/history-withdraw',
    element: <HistoryWithdraw />,
  },
  {
    path: '/history-deposit',
    element: <HistoryDeposit />,
  },

  {
    path: '/change-password',
    element: <ChangePassword />,
  },

  {
    path: '/change-password-withdraw',
    element: <ChangePasswordWithdraw />,
  },

  {
    path: '/message',
    element: <Message />,
  },

  {
    path: '/info',
    element: <Info />,
  },
  {
    path: '/game',
    element: <Game />,
  },
  {
    path: '/game/:id',
    element: <Game />,
  },
  {
    path: '/dautu',
    element: <DauTu />,
  },
  {
    path: '/account-infor',
    element: <AccountInfor />,
  },
  {
    path: '/thongbao',
    element: <ThongBao />,
  },
  {
    path: '/chinh-sach-bao-mat',
    element: <ChinhSachBaoMat />,
  },
  {
    path: '/chinh-sach-lai-xuat',
    element: <ChinhSach />,
  },
  {
    path: '/ve-chung-toi',
    element: <VeChungToi />,
  },
  {
    path: '/dieu-khoan-su-dung',
    element: <DieuKhoanSuDung />,
  },
  {
    path: '/xac-minh-danh-tinh',
    element: <XacMinh />,
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const WebsManagementRoutes = () => useRoutes(WebManagementLayout);
export default WebsManagementRoutes;
