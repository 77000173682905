import Layout from "./Layout";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { AppDispatch } from "@/store";
import { CButton } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import axios from '../../../shared/config/axios-interceptor';
import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast";
import { getProfile } from "../auth/auth.api";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { ITransaction } from "@/shared/model/transaction.model";
import { ResponseStatusUser } from "@/shared/enumeration/ResponseStatus";

const Withdraw = () => {
    const { location, navigate } = useRouter();

    const { user } = useSelector((state: RootState) => state.authentication);
    const dispatch = useDispatch<AppDispatch>();
    const [transaction, setTransaction] = useState<ITransaction | null>(null);

    const [money, setMoney] = useState(0);
    const [password1, setPassword1] = useState('');
    const [noBank, setNoBank] = useState(false);

    const [loading, setLoading] = useState(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (Number.isNaN(Number(value))) return
        setMoney(Number(value));
    }


    const getRutTienDangCho = async () => {
        try {
            const response = await axios.get(
                '/transaction/rut-tien-dang-cho'
            )
            setTransaction(response.data)
            console.log('Success:', response.data)
        } catch (error) {
            console.error('Error:', error)
        }
    }

    useEffect(() => {
        getRutTienDangCho()
        dispatch(getProfile());
    }, [])


    useEffect(() => {
        if (!user?.cardName || !user?.cardNumber || !user?.bankName) {
            setNoBank(true)
        }
    }, [user?.cardName, user?.cardNumber, user?.bankName])

    const onSubmit = async () => {
        if (user?.status == ResponseStatusUser.CAMRUTTIEN) {
            ToastError('Tài khoản của bạn bị cấm rút tiền. Vui lòng liên hệ CSKH!');
            return
        }

        if (transaction) {
            ToastError('Bạn có lệnh rút tiền đang chờ duyệt');
            return
        }

        if (money <= 0) {
            ToastError('Số tiền rút phải lớn hơn 0')
            return
        }

        if (money > (user?.money ?? 0)) {
            ToastError('Số tiền không hợp lệ')
            return
        }

        try {
            setLoading(true)
            const response1 = await axios.post('/user/checkpassword1', {
                password1: password1
            });

            if (!response1.data) {
                ToastError('Mật khẩu rút tiền sai')
                return
            }

            const response2 = await axios.post('/transaction', {
                money, type: 2, status: 0, message: money * 0.03
            });

            if (response2) {
                setLoading(false)
                setMoney(0)
                ToastSuccess('Hệ thống đang xử lý. Vui lòng chờ trong ít phút')
                dispatch(getProfile());
            }
        } catch (error) {
            dispatch(getProfile());
        }
    }


    if (noBank) {
        return <Navigate to="/add-bank" replace state={{ path: location.pathname }} />;
    }

    return (
        <Layout >
            <div className="box-withdraw">

                <div className="box-header">
                    <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                    <h5 className="m-0 text-white">Rút</h5>
                </div>

                <div className="box-content mt-4">

                    <div className="d-flex flex-column form-withdraw gap-2">
                        <label className="text-white fw-blod" style={{ fontSize: 15 }}>Số tiền rút</label>
                        <input type="text" onChange={(e) => onChange(e)} placeholder="Nhập số tiền rút" value={transaction ? Number(transaction.money) + Number(transaction.message) : money} />
                    </div>

                    <div className="sodu">Số dư: {new Intl.NumberFormat('vi-VN').format(user?.money || 0)}</div>
                    <div className="sodu">Phí dịch vụ: {transaction ? Number((Number(transaction.money) + Number(transaction.message)) * 0.03) : money * 0.03}</div>

                    <div className="d-flex flex-column form-withdraw gap-2 mt-3">
                        <label className="text-white fw-blod" style={{ fontSize: 15 }}>Mã bảo mật</label>
                        <input onChange={(e) => setPassword1(e.target.value)} value={password1} type="password" style={{ fontSize: 14 }} placeholder="Vui lòng nhập mật khẩu an toàn" />
                    </div>

                    <div className="bank mt-5">
                        <div>Thông tin ngân hàng</div>
                        <div>{user?.bank?.code}</div>
                        <div>{user?.cardNumber}</div>
                        <div>{user?.cardName}</div>
                    </div>

                    <div className="px-3 mt-4">
                        <CButton disabled={loading} className="w-100" onClick={onSubmit}>Xác nhận</CButton>
                    </div>

                </div>

            </div>
        </Layout>
    )
}

export default Withdraw;