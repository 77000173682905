import { ReactComponent as UploadIcon } from '@/assets/img/upload.svg';
import { CFormLabel, CImage } from '@coreui/react-pro';
import { ToastError } from '../toast/Toast';

export const imageExtension = ['jpeg', 'jpg', 'png', 'webp', 'bitmap'];
export const videoExtension = ['mp4', 'm4a', 'webm'];

export const checkErrorInputFile = (filesArr: File[]) => {
  let isValid = true;
  for (let i = 0; i < filesArr.length; i++) {
    const { type, size } = filesArr[i];
    const isValidImgExtention = !imageExtension.filter((e) => type.indexOf(e) !== -1).length;
    const maximumSizeBytes = 1024 * 1024 * 10;

    if (isValidImgExtention) {
      ToastError(`File tải lên phải có định dạng (${imageExtension.join(', ')})`);
      isValid = false;
      break;
    }
    if (size > maximumSizeBytes) {
      ToastError('File tải lên vượt quá dung lượng cho phép 10MB');
      isValid = false;
      break;
    }
  }
  return isValid;
};

const handleFileUpload = async (
  e: React.ChangeEvent<HTMLInputElement>,
  onFileChange: (file: File, fileSrc: string) => void
) => {
  const inputFiles = e.target.files;
  if (inputFiles && inputFiles[0]) {
    // setProfilePicFile(inputFiles[0]);
    if (!checkErrorInputFile([inputFiles[0]])) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      onFileChange(inputFiles[0], e.target?.result as string);
    };
    reader.readAsDataURL(inputFiles[0]);
  }
};

interface IAvatarUploadContainer {
  avatarSrc?: string;
  onFileChange: (file: File, fileSrc: string) => void;
}

const AvatarUploadContainer = (props: IAvatarUploadContainer) => {
  const { avatarSrc, onFileChange } = props;

  return (
    <div className="avatar-upload-container">
      <div className="image-container">
        {avatarSrc ? (
          <CImage
            color="secondary"
            rounded
            className="avatar-img"
            src={avatarSrc}
            style={{ width: '88px', height: '88px' }}
          />
        ) : (
          <div className="upload-container">
            <UploadIcon className="upload-icon" />
          </div>
        )}
      </div>
      <CFormLabel htmlFor="custom-file-input" className={`${avatarSrc ? 'p-image' : 'p-image-custom'}`}>
        {avatarSrc ? <UploadIcon className="upload-button" /> : <div className="add-image"></div>}
        <input
          id="custom-file-input"
          className="file-upload"
          type="file"
          onClick={(e) => (e.currentTarget.value = '')}
          // name="imageUrl"
          onChange={(e) => handleFileUpload(e, onFileChange)}
          // accept={inputFileTypesAccept}
          accept="image/*"
        />
      </CFormLabel>
    </div>
  );
};

export default AvatarUploadContainer;
