import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { CButton, CFormInput, CFormLabel } from "@coreui/react-pro";
import { useState } from "react";
import axios from '../../../shared/config/axios-interceptor';
import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { AppDispatch } from "@/store";
import { getProfile } from "../auth/auth.api";

const ChangePasswordWithdraw = () => {
    const { navigate } = useRouter();
    const dispatch = useDispatch<AppDispatch>()

    const { user } = useSelector((state: RootState) => state.authentication);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (index === 1) {
            setCurrentPassword(value)
        }
        if (index === 2) {
            setNewPassword(value)
        }
        if (index === 3) {
            setConfirmNewPassword(value)
        }
    }

    const onSubmit = async () => {
        if(user?.password1){
            if (!currentPassword || !newPassword || !confirmNewPassword) {
                ToastError('Vui lòng điền đầy đủ thông tin')
                return;
            }
            if (user?.password1 !== currentPassword) {
                ToastError('Mật khẩu cũ sai')
                return;
            }
        }

        if (!newPassword || !confirmNewPassword) {
            ToastError('Vui lòng nhập đầy đủ thông tin')
            return;
        }

        if(newPassword !== confirmNewPassword){
            ToastError('Xác nhận mật khẩu rút tiền không khớp')
            return;
        }

        try {
            const response = await axios.put('/user/' + user?.id, {
                password1: confirmNewPassword
            });
            ToastSuccess('Cập nhật mật khẩu rút tiền thành công')
            dispatch(getProfile())
            setCurrentPassword('')
            setNewPassword('')
            setConfirmNewPassword('')
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <Layout >
            <div className="box-add-bank">

                <div className="box-header">
                    <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                    <h5 className="m-0 text-white">Cài đặt mật khẩu rút tiền</h5>
                </div>

                <div className="box-form px-3">
                    {
                        user?.password1 && <div className="d-flex form-group-custom">
                            <CFormLabel htmlFor="cardName" className="col-sm-2 col-form-label text-nowrap">Mật khẩu rút tiền cũ</CFormLabel>
                            <CFormInput className="px-2" onChange={(e) => onChange(1, e)} type="password" id="cardName" placeholder="Mật khẩu cũ" value={currentPassword} />
                        </div>
                    }
                    <div className="d-flex form-group-custom">
                        <CFormLabel htmlFor="cardNumber" className="col-sm-2 col-form-label text-nowrap">Mật khẩu rút tiền mới</CFormLabel>
                        <CFormInput className="px-2" onChange={(e) => onChange(2, e)} type="password" id="cardNumber" placeholder="Mật khẩu mới" value={newPassword} />
                    </div>
                    <div className="d-flex form-group-custom">
                        <CFormLabel htmlFor="bankName" className="col-sm-2 col-form-label text-nowrap">Nhập lại mật khẩu rút tiền</CFormLabel>
                        <CFormInput className="px-2" onChange={(e) => onChange(3, e)} type="password" id="bankName" placeholder="Nhập lại mật khẩu mới" value={confirmNewPassword} />
                    </div>

                    <div className="px-3 mt-4">
                        <CButton className="w-100" onClick={onSubmit}>{user?.password1 ? 'Cập nhật' : 'Thiết lập'}</CButton>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ChangePasswordWithdraw;