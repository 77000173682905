import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

const ProductManagement = React.lazy(() => import('./ProductManagement'));

const ProductManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      // <ProtectedComponent requiredPerms={[]}>
        <ProductManagement />
      // </ProtectedComponent>
    ),
  },

  //   {
  //     path: `${RouteName.CREATE}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserUpdate />
  //       </ProtectedComponent>
  //     ),
  //   },

  //   {
  //     path: `:id/${RouteName.UPDATE}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserUpdate />
  //       </ProtectedComponent>
  //     ),
  //   },

  //   {
  //     path: `:id/${RouteName.DETAIL}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserDetail />
  //       </ProtectedComponent>
  //     ),
  //   },

  //   {
  //     path: '/:id/change-password',
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserChangePassword />
  //       </ProtectedComponent>
  //     ),
  //   },
  //   {
  //     path: `:id/${RouteName.HISTORY}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserHistory />
  //       </ProtectedComponent>
  //     ),
  //   },
  // {
  //   path: '/create',
  //   element: (
  //     <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
  //       <CommissionConfigsUpdate />
  //     </ProtectedComponent>
  //   ),
  // },
  { path: '*', element: <Navigate to="/404" /> },
];

const ProductManagementRoutes = () => useRoutes(ProductManagementLayout);
export default ProductManagementRoutes;
