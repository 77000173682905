import Layout from './Layout'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { CButton, CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import { IBank } from '@/shared/model/bank.model'

const AddBank = () => {
  const { navigate } = useRouter()
  const dispatch = useDispatch<AppDispatch>()

  const { user } = useSelector((state: RootState) => state.authentication)

  const [cardName, setCardName] = useState(user?.cardName || '')
  const [cardNumber, setCardNumber] = useState(user?.cardNumber || '')
  const [bankName, setBankName] = useState(user?.bankName || 0)
  const [phone, setPhone] = useState(user?.phone || '')
  const [listBank, setListBank] = useState<IBank[]>()

  const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (index === 1) {
      setCardName(value)
    }
    if (index === 2) {
      setCardNumber(value)
    }
    if (index === 4) {
      setPhone(value)
    }
  }

  const getBanks = async () => {
    try {
      const response = await axios.get('/bank?page=1&limit=100&status=ACTIVE')
      setListBank(response.data.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getBanks()
  }, [])

  const onSubmit = async () => {
    if(!cardName || !cardNumber || !bankName || !phone){
      ToastError('Vui lý điền đầy đủ thông tin')
      return
    }

    try {
      const response = await axios.put('/user/' + user?.id, {
        cardName,
        cardNumber,
        bankName,
        phone
      })
      if(response){
        ToastSuccess('Thêm ngân hàng thành công')
        dispatch(getProfile())
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }


  return (
    <Layout>
      <div className="box-add-bank">
        <div className="box-header">
          <ArrowLeft
            style={{ position: 'absolute', left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="m-0 text-white">Thêm thẻ ngân hàng</h5>
        </div>

        <div className="box-form px-3">
          <div className="form-group-custom">
            <CFormLabel
              htmlFor="bankName"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Tên ngân hàng
            </CFormLabel>

            <CFormSelect style={{ background: "#212122", outline: "none", border: "none", boxShadow: "none", fontSize: 14, color: "#fff" }}
              className="form-height-44 p-0"
              name="bankName"
              onChange={(e: any) => setBankName(e.target.value)}
              value={bankName}
            >
              <option value={10}>
                Chọn ngân hàng
              </option>

              {listBank?.map((item, index) => (
                <option key={index} value={item.bankId} selected={ true}>
                  {item.code} -  {item.name}
                </option>
              ))}

            </CFormSelect>

          </div>
          <div className="form-group-custom">
            <CFormLabel
              htmlFor="cardName"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Tên chủ tài khoản
            </CFormLabel>
            <CFormInput
              onChange={(e) => onChange(1, e)}
              type="text"
              id="cardName"
              placeholder="Nhập tên thật"
              value={cardName}
            />
          </div>
          <div className="form-group-custom">
            <CFormLabel
              htmlFor="cardNumber"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Số tài khoản
            </CFormLabel>
            <CFormInput
              onChange={(e) => onChange(2, e)}
              type="text"
              id="cardNumber"
              placeholder="Vui lòng nhập số tài khoản"
              value={cardNumber}
            />
          </div>

          <div className="form-group-custom">
            <CFormLabel
              htmlFor="phone"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Số điện thoại
            </CFormLabel>
            <CFormInput
              onChange={(e) => onChange(4, e)}
              type="text"
              id="phone"
              placeholder="Vui lòng nhập số điện thoại"
              value={phone}
            />
          </div>

          <p style={{ fontSize: 13 }} className="mt-3">
            Khách hàng thân ái,để bảo vệ sự an toàn cho tài sản của bạn,Vui lòng
            liên kết tên thật và cài đặt mật khẩu rút tiền,Nếu tên không khớp
            với tên tài khoản,sẽ không thể rút tiền.
          </p>

          <div className="px-3 mt-4">
            <CButton
              className="w-100"
              onClick={onSubmit}
              disabled={user?.bankName ? true : false}
            >
              Gửi
            </CButton>
          </div>

          {user?.bankName && (
            <div className="bank-box-bank mt-4">
              <div>Thông tin ngân hàng</div>
              <div>{user?.bank?.code}</div>
              <div>{user?.cardNumber}</div>
              <div>{user?.cardName}</div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default AddBank
