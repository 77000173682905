import avatar from '@/assets/img/avatar.jpg';
import BellIcon from '@/components/shared/icons/BellIcon';
import UserPlus01Icon from '@/components/shared/icons/UserPlus01Icon';
import Users02Icon from '@/components/shared/icons/Users02Icon';
import XIcon from '@/components/shared/icons/XIcon';
import { RootState } from '@/reducers';
import { IChat } from '@/shared/model/chat.model';
import { CAvatar, CContainer, CFormSwitch } from '@coreui/react-pro';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMessageInfo } from './message.reducer';

interface IMessageInfo {
  chatRoom: IChat;
}

const MessageInfo = (props: IMessageInfo) => {
  const { chatRoom } = props;
  const dispatch = useDispatch();
  const { bodyWidth } = useSelector((state: RootState) => state.container);

  const { initialState } = useSelector((state: RootState) => state.messageReducer);
  const { messageInfoShow } = initialState;

  const toggle = () => {
    dispatch(toggleMessageInfo(!messageInfoShow));
  };

  return (
    <CContainer
      className={`message-info-container ${messageInfoShow ? 'fixed' : ''}`}
      style={{ width: 0.25 * bodyWidth }}
    >
      <div className="chat-info-header flex-wrap">
        <span className="text-medium-sm">Thông tin nhóm</span>
        <XIcon onClick={toggle} className="cursor-pointer" />
      </div>
      <div className="message-info-item">
        <div className="d-flex align-items-center text-sm text-gray-neutral-700">
          <BellIcon className="me-2 text-gray-modern-700" />
          <span className="d-none d-sm-block">Thông báo</span>
        </div>
        <CFormSwitch />
      </div>
      <div className="message-info-item border-0">
        <div className="d-flex align-items-center text-sm text-gray-neutral-700">
          <Users02Icon className="me-2 text-gray-modern-700" />
          {chatRoom.number_of_members}
          <p className="d-none d-sm-block m-0 ms-1">thành viên</p>
        </div>
        <div className="add-member-icon">
          <UserPlus01Icon />
        </div>
      </div>

      <div className="list-chat-member">
        {chatRoom.users.map((user) => (
          <div key={`message-member-${user?.id}`} className="message-info-member">
            <div className="avatar-40 me-md-12">
              <CAvatar src={user.avatar || avatar} className="avatar-40" />
            </div>
            <div className="d-none d-md-block w-100 ms-12">
              <p className="chat-room-name mb-1">{user?.fullName || user?.username}</p>
              <p className="text-xs text-gray-modern-400 m-0">@{user?.username}</p>
            </div>
          </div>
        ))}
      </div>
    </CContainer>
  );
};

export default MessageInfo;
