import { formatVND } from '@/shared/utils/ultils'
import Layout from './Layout'
import image3 from '../../../assets/img/add.svg'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { IGame } from '@/shared/model/game.model'
import KyQuayGiaoDich from './KyQuayGiaoDich'
import SModal from '@/components/shared/Modal/SModal'
import {
  CButton,
  CModalContent,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import {
  ToastErrorBottom,
  ToastSuccessBottom,
} from '@/components/shared/toast/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { socket } from '@/shared/config/socket'
import { getProfile } from '../auth/auth.api'
import Ticket from './Ticket'
import Chart from './Chart'
import { NavLink } from 'react-router-dom'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { IProduct } from '@/shared/model/product.model'

const Game = () => {
  const { params } =  useRouter()
  const [phien, setPhien] = useState<number[]>([1, 2, 3, 4, 5])
  const [tienCuoc, setTienCuoc] = useState<number>(100000)
  const [chonPhien, setChonPhien] = useState<number>(0)
  const [cachChoi, setCachChoi] = useState<number>(1) // 1 => Mua, 2 => Bán
  const [product, setProduct] = useState<IProduct>() // 1 => Mua, 2 => Bán

  const [listPhien, setListPhien] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [ky, setKy] = useState<IGame | null>(null)
  const [load, setLoad] = useState<boolean>(false)
  const [disableDatCuoc, setDisableDatCuoc] = useState<boolean>(false)
  const [showDatCuoc, setShowDatCuoc] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)

  const getCurrency = async () => {
    try {
      const response = await axios.get(
        '/product/' + (params?.id ? params?.id : 2),
      )
      setProduct(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getPhien = async () => {
    try {
      const response = await axios.get(
        '/settings/1',
      )

      if (response?.data) {
        const { content2, content3 } = response.data

        if (content2 && content3) {

          const listDataPhien: any = [];

          content2.split(',').forEach(async (phien: any) => {
            const phienNumber: Number = Number(phien.trim());
            if (typeof phienNumber === 'number') {
              listDataPhien.push({ name: phienNumber, description: 0 });
            }
          });



          content3.split(',').forEach(async (phien: any, index: number) => {
            const phienNumber: Number = Number(phien.trim());
            if (typeof phienNumber === 'number') {
              listDataPhien[index].description = phienNumber;
            }
          });

          setListPhien(listDataPhien);
        }

      }
      // setKy(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKy = async () => {
    try {
      const response = await axios.get(
        '/draws/get-current?phien=' + chonPhien / 60,
      )
      setKy(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getPhien()
    getKy()
  }, [chonPhien, load])


  useEffect(() => {
    getCurrency()
  }, [params?.id])

  const submitHandle = async () => {
    // if (user?.status == ResponseStatusUser.CAMGIAODICH) {
    //   ToastError('Tài khoản của bạn bị cấm giao dịch. Vui lòng liên hệ CSKH!');
    //   return
    // }


    if (tienCuoc <= 0) {
      ToastErrorBottom('Khối lượng lệnh phải lớn hơn 0')
      return
    }

    if(!chonPhien || chonPhien === 0){
      ToastErrorBottom('Vui lòng chọn phiên')
      return
    }

    if (
      Number(user?.money ?? 0) <= 0 ||
      Number(tienCuoc) > Number(user?.money ?? 0)
    ) {
      ToastErrorBottom('Số dư không đủ để đặt lệnh')
      return
    }


    if(!ky?.id) {
      ToastErrorBottom('Vui lòng chờ kỳ tiếp theo')
      return
    }

    try {
      setLoading(true)
      const response = await axios.post('/history', {
        money: tienCuoc,
        voted: cachChoi,
        KyId: ky?.id,
        userId: user?.id,
      })

      if(response){
        setLoading(false)
      }
      if (response.status == 201) {
        ToastSuccessBottom('Đặt lệnh thành công')
        socket.emit('user_new_history', { userId: user?.id })
        dispatch(getProfile())
        setShowDatCuoc(false)
        return
      }
      dispatch(getProfile())
      setShowDatCuoc(false)
    } catch (error) {
      dispatch(getProfile())
      setShowDatCuoc(false)
      console.error('Error:', error)
    }
  }

  const handleShowModal = () => {
    if (disableDatCuoc) {
      ToastErrorBottom('Hết thời gian đặt cược')
      return
    }


    setShowDatCuoc(true)
  }

  return (
    <div className="box-game">
      <Layout>
        <div className="d-flex justify-content-between align-items-center p-2">
          <div style={{ fontSize: 12 }} className="fw-bold">
            { product?.name }
          </div>
          <div
            className="fw-bold"
            style={{ color: 'rgb(0, 190, 6)', fontSize: 15 }}
          >
            QUYỀN KỲ HẠN
          </div>
          <NavLink to={'/history-order'} style={{ color: '#fff', fontSize: 13 }} className='text-decoration-none'>
            Lịch sử
          </NavLink>
        </div>

        <Chart product={product} />

        <Ticket product={product} />

        <div className="box-content mt-2 px-3">
          <div className="d-flex align-items-center gap-3">
            <div style={{ fontSize: 13 }}>
              Số dư: {formatVND(user?.money ?? 0)}
            </div>
            <NavLink to={'/withdraw1'}>
              <img src={image3} width={18} style={{ marginBottom: 2 }} />
            </NavLink>
          </div>

          <KyQuayGiaoDich
            load={load}
            ky={ky}
            setDisableDatCuoc={setDisableDatCuoc}
            setLoad={setLoad}
          />

          <div className="box-cachchoi d-flex align-items-center justify-content-between gap-3">
            <div
              className={'btn-custom mua' + (cachChoi === 1 ? ' active' : '')}
              onClick={() => setCachChoi(1)}
            >
              Mua
            </div>
            <div
              className={'btn-custom ban' + (cachChoi === 2 ? ' active' : '')}
              onClick={() => setCachChoi(2)}
            >
              Bán
            </div>
          </div>

          <div className="mt-2 box-phien d-flex flex-column gap-1">
            <div style={{ fontSize: 13 }}>Phiên</div>
            <div className="list-phien d-flex gap-2" style={{ flexWrap: 'wrap' }}>
              {listPhien?.map((item: any, key: number) => (
                <div
                  onClick={() => setChonPhien(item.name * 60)}
                  key={key}
                  className={item.name * 60 === chonPhien ? 'active flex-column item-a' : 'flex-column item-a'}
                >
                  <div>{item.name * 60} giây</div>

                  <div>{item.description}%</div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-2 box-khoi-lenh d-flex flex-column gap-1">
            <div style={{ fontSize: 13 }}>Khối lượng lệnh</div>
            <div className="list-phien d-flex flex-column gap-2">
              <input
                type="text"
                onChange={(e: any) => setTienCuoc(e.target.value)}
                value={tienCuoc}
              />
            </div>
          </div>

          <div className="text-center d-flex align-items-center justify-content-center mt-3">
            <div
              onClick={handleShowModal}
              className={
                cachChoi === 1
                  ? 'datlenh d-flex align-items-center justify-content-center fw-bold mua'
                  : 'datlenh d-flex align-items-center justify-content-center fw-bold ban'
              }
            >
              Đặt lệnh
            </div>
          </div>
        </div>

        <SModal
          visible={showDatCuoc}
          // onClose={handleOnClose}
          backdrop="static"
          className="custom-modal chitietdatcuoc-modal"
          alignment="center"
        // style={{backgroundColor: "#2e2e2e"}}
        >
          <CModalHeader>
            <h6 className="text-center m-0" style={{ color: '#fff' }}>
              Chi tiết đặt lệnh
            </h6>
          </CModalHeader>

          <CModalContent>
            <div className="box-content mx-3 mt-2">
              <div className="item d-flex " style={{ backgroundColor: '#222' }}>
                <div style={{ width: '100%' }}>Đặt lệnh phiên</div>
                <div style={{ width: '100%' }}>#{ky?.id}</div>
              </div>

              <div className="item d-flex ">
                <div style={{ width: '100%' }}>Lệnh</div>
                <div style={{ width: '100%' }}>
                  {cachChoi === 1 ? 'Mua' : 'Bán'}
                </div>
              </div>

              <div className="item d-flex " style={{ backgroundColor: '#222' }}>
                <div style={{ width: '100%' }}>Phiên giá</div>
                <div style={{ width: '100%' }}>{chonPhien}</div>
              </div>

              <div className="item d-flex ">
                <div style={{ width: '100%' }}>Khối lượng lệnh</div>
                <div style={{ width: '100%', color: 'rgb(255, 203, 59)' }}>
                  {formatVND(tienCuoc)}
                </div>
              </div>
            </div>
          </CModalContent>

          <CModalFooter className="mt-2 pb-3 justify-content-center">
            <CButton
              onClick={() => setShowDatCuoc(false)}
              style={{ color: '#fff', backgroundColor: '#5c5c5c' }}
            >
              Hủy bỏ
            </CButton>
            <CButton
              onClick={submitHandle}
              disabled={loading}
              style={{ color: '#fff', backgroundColor: '#094f8d' }}
            >
              Xác nhận
            </CButton>
          </CModalFooter>
        </SModal>
      </Layout>
    </div>
  )
}

export default Game
