import { SVGProps } from 'react';
const Users02Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || 16}
    height={props?.height || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M10.6666 2.31184C11.6544 2.80274 12.3333 3.82209 12.3333 5C12.3333 6.17791 11.6544 7.19726 10.6666 7.68816M11.9999 11.1776C13.0076 11.6336 13.9149 12.3767 14.6666 13.3333M1.33325 13.3333C2.63091 11.6817 4.39271 10.6667 6.33325 10.6667C8.2738 10.6667 10.0356 11.6817 11.3333 13.3333M9.33325 5C9.33325 6.65685 7.99011 8 6.33325 8C4.6764 8 3.33325 6.65685 3.33325 5C3.33325 3.34315 4.6764 2 6.33325 2C7.99011 2 9.33325 3.34315 9.33325 5Z"
      stroke={props?.stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Users02Icon;
