import Layout from "./Layout";
import avatar from '../../../assets/img/avatar.gif';
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { AppDispatch } from "@/store";
import { CButton } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import axios from '../../../shared/config/axios-interceptor';
import { ToastError, ToastErrorBottom, ToastSuccess } from "@/components/shared/toast/Toast";
import { getProfile } from "../auth/auth.api";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { ITransaction } from "@/shared/model/transaction.model";

const Withdraw_1 = () => {
    const { location, navigate } = useRouter();

    const { user } = useSelector((state: RootState) => state.authentication);
    const dispatch = useDispatch<AppDispatch>();

    const [money, setMoney] = useState(0);
    const [noBank, setNoBank] = useState(false);

    const [transaction, setTransaction] = useState<ITransaction | null>(null);

    const getNapTienDangCho = async () => {
        try {
          const response = await axios.get(
            '/transaction/nap-tien-dang-cho'
          )
          setTransaction(response.data)
          console.log('Success:', response.data)
        } catch (error) {
          console.error('Error:', error)
        }
      }
    
      useEffect(() => {
        getNapTienDangCho()
      }, [])


    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (Number.isNaN(Number(value))) return
        setMoney(Number(value));
    }

    useEffect(() => {
        if (!user?.cardName || !user?.cardNumber || !user?.bankName) {
            setNoBank(true)
        }
    }, [user?.cardName, user?.cardNumber, user?.bankName])

    const onSubmit = async () => {

        if(transaction){
            ToastError('Bạn đang có một lệnh chờ duyệt');
            return;
        }

        if (money <= 0) {
            ToastError('Số tiền nạp phải lớn hơn 0')
            return
        }

        try {

            const response = await axios.post('/transaction', {
                money, type: 1, status: 0
            });
            setMoney(0)
            ToastSuccess('Bạn đã gửi lệnh thành công. Vui lòng chờ duyệt.')
            dispatch(getProfile());
            getNapTienDangCho();
        } catch (error) {
            console.error('Error:', error);
            dispatch(getProfile());
        }
    }



    console.log(transaction);
    

    return (
        <Layout >
            <div className="box-withdraw">

                <div className="box-header">
                    <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                    <h5 className="m-0 text-white">Mua</h5>
                </div>

                <div className="box-content mt-4">

                    <div className="d-flex flex-column form-withdraw gap-2">
                        <label className="text-white fw-blod">Số tiền nạp</label>
                        <input type="text" onChange={(e) => onChange(e)} placeholder="Nhập số tiền rút" value={transaction ? Number(transaction.money) : money} />
                    </div>

                    {transaction ? <p className="sodu1 text-danger mt-2">Bạn đang có một lệnh chờ duyệt</p> : ''}

                    <p className="sodu1 text-white mt-2">Số lương VND nhận được: <span style={{color: "rgb(0, 189, 126)"}}>{new Intl.NumberFormat('vi-VN').format(transaction ? transaction.money : money)}</span></p>

                    <p className="sodu1 text-white mt-2 mb-1 " style={{fontSize: 14}}>Phí giao dịch: 0 VND</p>
                    <p className="mb-1" style={{fontSize: 14}}>Giao dịch tối thiểu: 1,000,000 VND / 1 giao dịch</p>
                    <p className="mb-1" style={{fontSize: 14}}>Giao dịch tối đa: 10,000,000,000 VND / 1 giao dịch</p>

                    <div className="px-3 mt-4">
                        <CButton className="w-100" onClick={onSubmit}>Gửi</CButton>
                    </div>

                </div>

            </div>
        </Layout>
    )
}

export default Withdraw_1;