import TimeIcon from '@/components/shared/icons/web/TimeIcon'
import { IGame } from '@/shared/model/game.model'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

interface IData {
  setDisableDatCuoc: any
  setLoad: any
  load: boolean
  ky: IGame | null
}
const KyQuayGiaoDich = (props: IData) => {
  const { setDisableDatCuoc, setLoad, load, ky } = props

  const [timeInterval, setTimeInterval] = useState<number>(0)

  useEffect(() => {
    const gameEndTime = dayjs(ky?.endTime).add(2, 'seconds')

    const interval = setInterval(() => {
      const now = dayjs()
      const difference = gameEndTime.diff(now, 'seconds')

      if (difference > 0) {
        // const hours = Math.floor(difference / (1000 * 60 * 60))
        // const minutes = Math.floor(
        //     (difference % (1000 * 60 * 60)) / (1000 * 60),
        // )
        // const seconds = Math.floor((difference % (1000 * 60)) / 1000)

        // if (minutes === 0 && seconds < 15) {
        //     setDisableDatCuoc(true)
        // } else {
        //     setDisableDatCuoc(false)
        // }

        // let m = minutes < 10 ? '0' + minutes : minutes
        // let s = seconds < 10 ? '0' + seconds : seconds
        // const formattedCountdown = `${m}:${s}`
        setTimeInterval(difference)
      } else {
        setLoad(!load)
        setTimeInterval(0)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [ky?.endTime, load])

  return (
    <div className="my-1 box-huongdan d-flex align-items-center justify-content-around gap-3">
      <div style={{ fontSize: 12 }}>Giao dịch: {ky?.id}</div>
      <div style={{ fontSize: 12, color: '#ffca2d' }}>
        {timeInterval < 3 ? 'Đang chờ kết quả' : 'Đếm ngược: ' + timeInterval.toString() + 's'}
      </div>
    </div>
  )
}

export default KyQuayGiaoDich
