import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useSelector } from 'react-redux'
import Layout from './Layout'
import avatar from '../../../assets/img/introduce.jpg'
import axios from '../../../shared/config/axios-interceptor'
import { useEffect, useState } from 'react'

const ChinhSach = () => {
  const { navigate } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication)

  const [data, setData] = useState<any>()

  const getPhien = async () => {
    try {
      const response = await axios.get('/settings/1')
      
      if (response?.data) {
        const { content5, content6 } = response.data

        if (content5 && content6) {

          const listDataPhien: any = [];

          content5.split(',').forEach(async (phien: any) => {
            const phienNumber: Number = Number(phien.trim());
            if (typeof phienNumber === 'number') {
              listDataPhien.push({ name: phienNumber, description: 0 });
            }
          });



          content6.split(',').forEach(async (phien: any, index: number) => {
            const phienNumber: Number = Number(phien.trim());
            if (typeof phienNumber === 'number') {
              listDataPhien[index].description = phienNumber;
            }
          });

          setData(listDataPhien);
        }

      }

    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getPhien();
  }, [])


  if(!data) return <></>

  return (
    <Layout>
      <div className="box-history-order">
        <div className="box-header d-flex justify-content-center align-items-center">
          <ArrowLeft
            style={{ position: 'absolute', left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="text-white text-center mb-0 mt-0">
            Chính sách lãi qua đêm
          </h5>
        </div>

        <div className="box-header py-3 px-3">
          <p className="fw-bold" style={{ fontSize: 14 }}>
            Lãi qua đêm là tiện ích cho phép người dùng nhận lãi đến {data[4]?.description ?? 0}% sau
            0h00 hằng ngày khi nắm giữ số lượng vốn đầu tư nhất định với số
            lượng tối thiểu định sẵn.
          </p>
          <p style={{ fontSize: 14 }}>
            Tính năng này áp dụng với mọi khách hàng sở hữu số vốn đạt yêu cầu.
          </p>

          <img width={'100%'} height={'100%'} src={avatar} className="mb-3" />

          <p style={{ fontSize: 14 }}>
            Tại đây sẽ hiển thị các thông tin chi tiết về các mức lãi dành cho
            khách hàng:
          </p>

          <p style={{ fontSize: 14 }}>
            - Lãi lên tới {data[0]?.description ?? 0}% dành cho khách hàng có mức vốn từ {data[0]?.name ?? 0} trở lên.
          </p>

          <p style={{ fontSize: 14 }}>
            - Lãi lên tới {data[1]?.description ?? 0}% dành cho khách hàng có mức vốn từ {data[1]?.name ?? 0} trở lên.
          </p>

          <p style={{ fontSize: 14 }}>
            - Lãi lên tới {data[2]?.description ?? 0}% dành cho khách hàng có mức vốn từ {data[2]?.name ?? 0} trở lên.
          </p>

          <p style={{ fontSize: 14 }}>
            - Lãi lên tới {data[3]?.description ?? 0}% dành cho khách hàng có mức vốn từ {data[3]?.name ?? 0} trở lên.
          </p>
          <p style={{ fontSize: 14 }}>
            - Lãi lên tới {data[4]?.description ?? 0}% dành cho khách hàng có mức vốn từ {data[4]?.name ?? 0} trở lên.
          </p>
        </div>
      </div>
    </Layout>
  )
}
export default ChinhSach
