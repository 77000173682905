import { IHistory } from '@/shared/model/history.model'
import Layout from './Layout'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import { IGame } from '@/shared/model/game.model'
import { formatUSD, formatUSDNoCurrency } from '@/shared/utils/ultils'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { IProduct } from '@/shared/model/product.model'

const History = () => {
  const [listProduct, setListProduct] = useState<IProduct[]>([])  
  const [listBottomUsdt, setListBottomUsdt] = useState<IProduct[]>([])
  const getListProduct = async () => {
    try {
      const response = await axios.get(
        '/product?status=ACTIVE',
      )
      setListProduct(response?.data?.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getListProduct()
  }, [])

  useEffect(() => {
    let a = setInterval(() => {
      // random from -1 to 1

      const newBottomUsdt: any = listProduct?.map((item: IProduct) => {
        const ran = Math.random() * Number(item?.amplitude ?? 2) - 1;
        const random: number = ran
        const random1: number = ran

        return {
          ...item,
          price: (Number(item.price) + random).toFixed(4),
          percent: random1.toFixed(2),
        }
      })

      setListBottomUsdt(newBottomUsdt)
    }, 1000)

    return () => {
      clearInterval(a)
    }
  }, [listProduct])

  const { navigate } = useRouter();

  const navigateGame = (item: any) => {
    navigate('/game/' + item?.id )
  }

  return (
    <Layout>
      <div className="box-history">
        <div className="box-header">
          <div className="fw-bold m-0">Bảng giá</div>
        </div>

        <div className="box-tai-san mt-3 px-2">

          <div className="content">
            <div className="heading item d-flex">
              <div className="i">Cặp giao dịch</div>
              <div className="i text-center">Giá hiện tại</div>
              <div className="i text-end">Tăng giảm 24h</div>
            </div>

            {listBottomUsdt.map((item: any, index: number) => (
                <div
                  onClick={() => navigateGame(item)}
                  className="heading item d-flex align-items-centerr"
                  key={index}
                >
                  <div className="i">
                    <div>{item.name}</div>
                    <span>Security data</span>
                  </div>
                  <div className="i text-center">
                    <div>{formatUSDNoCurrency(Math.abs(item.price))}</div>
                    <span>{formatUSD(Math.abs(item.price))}</span>
                  </div>
                  <div className="i text-end align-content-center">
                    <div
                      className={
                        item.percent > 0 ? 'tanggiam24' : 'tanggiam24 down'
                      }
                    >
                      {item.percent}%
                    </div>
                  </div>
                </div>
              ))}
              
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default History
