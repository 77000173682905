import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useSelector } from 'react-redux'
import Layout from './Layout'
import avatar from '../../../assets/img/introduce.jpg'

const DieuKhoanSuDung = () => {
  const { navigate } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication)
  return (
    <Layout>
      <div className="box-history-order">
        <div className="box-header d-flex justify-content-center align-items-center">
          <ArrowLeft
            style={{ position: 'absolute', left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="text-white text-center mb-0 mt-0">
            Điều khoản sử dụng
          </h5>
        </div>


        <div className='p-3'>
          <p className='mb-2'>Chúng tôi thu thập thông tin cá nhân của người dùng để hỗ trợ cho việc sử dụng ứng dụng.</p>
          <p className='mb-2'>Thông tin cá nhân của người dùng sẽ được bảo mật và chỉ được sử dụng cho mục đích thu thập.</p>
          <p className='mb-2'>Người dùng có quyền yêu cầu chúng tôi xóa thông tin của họ khỏi hệ thống.</p>
          <p className='mb-2'>Chúng tôi không chia sẻ thông tin cá nhân của người dùng với bên thứ ba ngoại trừ khi có yêu cầu pháp lý hoặc sự đồng ý của người dùng.</p>
          <p className='mb-2'>Chúng tôi sẽ bảo vệ thông tin cá nhân của người dùng bằng các biện pháp bảo mật vật lý và kỹ thuật số.</p>
          <p className='mb-4'>Người dùng có quyền yêu cầu chúng tôi xóa thông tin của họ khỏi hệ thống.</p>


          <p>Quyền riêng tư</p>
          <p className='mb-2'>Người dùng có quyền kiểm soát thông tin của họ và quyết định liệu họ có muốn cung cấp thông tin cá nhân cho ứng dụng hay không.</p>
          <p className='mb-2'>Người dùng có quyền yêu cầu chúng tôi chỉnh sửa thông tin của họ nếu thông tin đó không chính xác hoặc cập nhật.</p>         <p>
          <p className='mb-2'>Chúng tôi cam kết bảo vệ quyền riêng tư của người dùng và không sử dụng thông tin cá nhân của họ cho bất kỳ mục đích nào khác ngoài mục đích thu thập.</p>
          <p className='mb-2'>Người dùng có quyền yêu cầu chúng tôi xóa thông tin của họ khỏi hệ thống và chúng tôi sẽ tuân thủ yêu cầu đó.</p>
          <p className='mb-2'>Chúng tôi sẽ cập nhật chính sách và quyền riêng tư của mình để đáp ứng các yêu cầu và nhu cầu của người dùng.</p>
          <p className='mb-2'>Lưu ý: Bạn nên tham khảo với một luật sư để đảm bảo chính sách và quyền riêng tư của bạn tuân thủ đầy đủ các quy định pháp luật địa phương và quốc gia.</p>












          </p>
        </div>

      </div>
    </Layout>
  )
}
export default DieuKhoanSuDung
