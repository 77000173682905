import { SVGProps } from "react"
const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width||21}
    height={props?.height||20}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill={props?.fill}
      d="M7.098 11.938c0-.408 0-.612.046-.803.04-.17.108-.333.2-.482.102-.168.246-.312.535-.6l7.969-7.97a1.768 1.768 0 0 1 2.5 2.5l-7.97 7.97c-.287.288-.432.432-.6.535-.149.091-.312.159-.482.2-.191.045-.395.045-.803.045H7.098v-1.395Z"
      opacity={0.12}
    />
    <path
      stroke={props?.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.67}
      d="M9.598 3.333h-3.5c-1.4 0-2.1 0-2.635.273A2.5 2.5 0 0 0 2.37 4.698c-.272.535-.272 1.235-.272 2.635v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.534.272 1.235.272 2.635.272h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.092-1.093c.273-.534.273-1.234.273-2.635v-3.5m-10 2.5h1.395c.408 0 .612 0 .803-.046.17-.04.333-.108.482-.2.168-.102.313-.246.6-.535l7.97-7.969a1.768 1.768 0 0 0-2.5-2.5l-7.97 7.97c-.288.288-.432.432-.535.6-.091.15-.158.312-.2.482-.045.191-.045.395-.045.803v1.395Z"
    />
  </svg>
)
export default EditIcon
