import React, { useEffect } from 'react'


interface IChartProps {
  product: any
}
const Chart = ({ product }: IChartProps) => {
  useEffect(() => {
    if (product?.code) {
      console.log(product?.code);
      const script = document.createElement('script')
      script.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js'
      script.async = true
      script.innerHTML = JSON.stringify({
        autosize: true,
        symbol: product?.code,
        interval: '1',
        timezone: 'Asia/Ho_Chi_Minh',
        theme: 'dark',
        style: '3',
        locale: 'vi_VN',
        allow_symbol_change: true,
        save_image: false,
        calendar: false,
        support_host: 'https://www.tradingview.com',
        backgroundColor: '#000',
        hide_legend: true,
        // hide_volume: true,
      })

      document
        .getElementsByClassName('tradingview-widget-container__widget')[0]
        .appendChild(script)
    }
  }, [product?.code])

  return (
    <div
      className="tradingview-widget-container position-relative"
      style={{ height: '100%', width: '100%' }}
    >
      <div
        className="logo"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1
          style={{
            zIndex: 9999,
            fontSize: '40px',
            color: 'rgb(255 255 255)',
            opacity: 0.2,
            fontFamily: '"Roboto", sans-serif',
          }}
        >
          CURRENCYSTAR
        </h1>
      </div>
      <div
        className="tradingview-widget-container__widget"
        style={{ height: 250, width: '100%' }}
      ></div>
    </div>
  )
}

export default Chart