import { SVGProps } from "react"
const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 20}
    height={props?.height || 20}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke={props?.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="m17.5 17.5-3.625-3.625m1.958-4.708a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0Z"
    />
  </svg>
)
export default SearchIcon
