import {
  CCarousel,
  CCarouselItem,
  CImage,
} from '@coreui/react-pro'
import Layout from './Layout'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import image1 from '../../../assets/img/banner/1.gif'
import image2 from '../../../assets/img/banner/2.gif'
import image3 from '../../../assets/img/banner/3.gif'
import image4 from '../../../assets/img/banner/4.gif'
import image5 from '../../../assets/img/banner/5.gif'
import image6 from '../../../assets/img/banner/6.gif'
import image7 from '../../../assets/img/banner/7.gif'
import image8 from '../../../assets/img/banner/8.gif'
import image9 from '../../../assets/img/banner/9.gif'
import image10 from '../../../assets/img/banner/10.gif'
import image11 from '../../../assets/img/banner/11.gif'
import image12 from '../../../assets/img/banner/12.gif'
import { NavLink } from 'react-router-dom'
import avatar from '../../../assets/img/avatar_default.jpg'
import {
  formatUSD,
  formatUSDNoCurrency,
} from '@/shared/utils/ultils'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { IProduct } from '@/shared/model/product.model'

const Home = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication)

  const [visible, setVisible] = useState(true)

  const [listProduct, setListProduct] = useState<IProduct[]>([])
  const [listProductWeight, setListProductWeight] = useState<IProduct[]>([])
  const [listBottomUsdt, setListBottomUsdt] = useState<IProduct[]>([])
  const [listTopUsdt, setListTopUsdt] = useState<any>([])

  const getListProduct = async () => {
    try {
      const response = await axios.get(
        '/product?status=ACTIVE&weight=0',
      )
      setListProduct(response?.data?.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getListProductWeight = async () => {
    try {
      const response = await axios.get(
        '/product?status=ACTIVE&weight=1',
      )
      setListProductWeight(response?.data?.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getListProduct()
    getListProductWeight()
  }, [])

  console.log(listProductWeight);

  useEffect(() => {
    let a = setInterval(() => {
      // random from -1 to 1

      const newTopUsdt = listProductWeight?.map((item: any) => {
        const ran = Math.random() * Number(item?.amplitude ?? 2) - 1;
        const random: number = ran
        const random1: number = ran

        return {
          ...item,
          price: (Number(item.price) + random).toFixed(4),
          percent: random1.toFixed(2),
        }
      })

      const newBottomUsdt: any = listProduct?.map((item: IProduct) => {
        const ran = Math.random() * Number(item?.amplitude ?? 2) - 1;
        const random: number = ran
        const random1: number = ran

        return {
          ...item,
          price: (Number(item.price) + random).toFixed(4),
          percent: random1.toFixed(2),
        }
      })

      setListTopUsdt(newTopUsdt)
      setListBottomUsdt(newBottomUsdt)
    }, 1000)

    return () => {
      clearInterval(a)
    }
  }, [listProductWeight])

  const navigateGame = (item:any) => {
    navigate('/game/' + item.id)
  }
  return (
    <Layout>
      <div className="home-page">
        <div className="d-flex justify-content-between align-items-center px-2">
          <div className="box-header d-flex align-items-center gap-2 ">
            <img src={avatar} />
            <div className="content">
              <div>Xin chào: {user?.username}</div>
              <span>ID: {user?.id}</span>
            </div>
          </div>

          <NavLink
            style={{ fontStyle: "italic", color: '#16ba0c', fontSize: 12, backgroundColor: '#002201' }}
            className={'text-decoration-none px-3 py-1 rounded-4'}
            to={'/message'}
          >
            CSKH
          </NavLink>
        </div>

        <div className="box-content px-2">
          <div className="mt-3 banner-slide">
            <CCarousel controls interval={3000}>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image1} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image2} alt="slide 2" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image3} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image4} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image5} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image6} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image7} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image8} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image9} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image10} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image11} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={image12} alt="slide 3" />
              </CCarouselItem>
            </CCarousel>
          </div>

          <div className="box-pho-bien mt-3">
            <div className="mb-2">
              <strong style={{ fontSize: 14 }}>Phổ biến</strong>
            </div>
            <div className="d-flex justify-content-between gap-3">
              {listTopUsdt.map((item: any, index: number) => (
                <div
                  key={index}
                  onClick={() => navigateGame(item)}
                  className="text-center item"
                >
                  <div>{item.name}</div>
                  <div>{formatUSD(Math.abs(item.price))}</div>
                  <div
                    className={
                      item.percent > 0 ? 'text-success m-0' : 'text-danger m-0'
                    }
                  >
                    {item.percent}%
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="box-tai-san mt-3">
            <div className="mb-2">
              <strong style={{ fontSize: 14 }}>Tài sản</strong>
            </div>

            <div className="content">
              <div className="heading item d-flex">
                <div className="i">Cặp giao dịch</div>
                <div className="i text-center">Giá hiện tại</div>
                <div className="i text-end">Tăng giảm 24h</div>
              </div>

              {listBottomUsdt.map((item: any, index: number) => (
                <div
                  onClick={() => navigateGame(item)}
                  className="heading item d-flex align-items-centerr"
                  key={index}
                >
                  <div className="i">
                    <div>{item.name}</div>
                    <span>Security data</span>
                  </div>
                  <div className="i text-center">
                    <div>{formatUSDNoCurrency(Math.abs(item.price))}</div>
                    <span>{formatUSD(Math.abs(item.price))}</span>
                  </div>
                  <div className="i text-end align-content-center">
                    <div
                      className={
                        item.percent > 0 ? 'tanggiam24' : 'tanggiam24 down'
                      }
                    >
                      {item.percent}%
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="box-new mt-3">
            <div>
              <strong style={{ fontSize: 14 }}>Tin tức mới</strong>
            </div>
            <p className="intro-news mt-2">
              Tuyên bố miễn trừ trách nhiệm đầy đủ: Nền tảng này bao gồm các ý
              kiến của bên thứ ba. Chúng tôi không xác nhận tính chính xác của
              họ. Giá tài sản kỹ thuật số có thể biến động. Làm nghiên cứu của
              riêng bạn.
            </p>
            <div className="post-news">
              <div className="date">10.01.2024</div>{' '}
              <div className="box-content ps-2">
                <h4 className="mt-2">
                  Currencystar ra mắt tính năng Đồng hành đầu tư
                </h4>{' '}
                <p>
                  {' '}
                  Nắm bắt được nhu cầu hiện hữu trên con đường đầu tư chuyên
                  nghiệp của khách hàng. Currencystar ra mắt tính năng “Đồng hành đầu
                  tư cùng Currencystar” trên ứng dụng với những lợi ích siêu nổi bật.{' '}
                </p>
              </div>
            </div>
            <div className="post-news">
              <div className="date">10.01.2024</div>{' '}
              <div className="box-content ps-2">
                <h4 className="mt-2">
                  Currencystar: Trái phiếu chuyển đổi là gì? Lợi ích nhà đầu tư nhận
                  được{' '}
                </h4>{' '}
                <p>
                  {' '}
                  Trái phiếu chuyển đổi là convertible bonds - trái phiếu được
                  công ty cổ phần phát hành. Đặc điểm chính của hình thức này là
                  trái chủ sẽ chuyển đổi trái phiếu thành cổ phiếu tại thời điểm
                  cụ thể nào đó, căn cứ vào thời gian và tỷ lệ đầu tư trái
                  phiếu.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <SModal
          visible={visible}
          onClose={() => setVisible(false)}
          backdrop="static"
          className="custom-modal"
          alignment="center"
        >
          <CModalBody>
            <h5 className="text-center my-2">Thông báo</h5>

            <div className='text-center mt-3'>
              <p className='my-3'>Phiên bản thử nghiệm . Vui lòng không nạp tiền vào phiên bản này. Tất cả hình thức nạp tiền vào đây đều không rút được tiền</p>
            </div>

          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton onClick={() => setVisible(false)} style={{background: "#FD0404"}}>Xác nhận</CButton>
          </CModalFooter>
        </SModal>
      </div> */}
    </Layout>
  )
}

export default Home
