import AccountIcon from '@/components/shared/icons/web/AccountIcon'
import CskhIcon from '@/components/shared/icons/web/CskhIcon'
import HeThongIcon from '@/components/shared/icons/web/HeThongIcon'
import HomeIcon from '@/components/shared/icons/web/HomeIcon'
import { NavLink } from 'react-router-dom'
import banggia from '../../../assets/img/banggia.png'
import quyenkyhan from '../../../assets/img/quyenkyhan.svg'
import dautu from '../../../assets/img/dautu.svg'
import toi from '../../../assets/img/toi.png'
import home from '../../../assets/img/home.png'
import chat from '../../../assets/img/chat-3.png'

const MenuBottom = () => {
  return (
    <div className="menu-bottom d-flex justify-content-between align-items-center">
      <NavLink
        to={'/'}
        className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center"
      >
        <img width={20} src={home} />
        <span>Trang chủ</span>
      </NavLink>
      <NavLink
        to={'/history'}
        className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center"
      >
        <img width={20} src={banggia} />
        <span>Bảng giá</span>
      </NavLink>
      <NavLink
        to={'/message'}
        className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center"
      >
        <img width={20} src={chat} />
        <span>CSKH</span>
      </NavLink>
      <NavLink
        to={'/game'}
        className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center"
      >
        <img width={20} src={quyenkyhan} />
        <span>Quyền kỳ hạn</span>
      </NavLink>
      <NavLink
        to={'/dautu'}
        className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center"
      >
        <img width={20} src={dautu} />
        <span>Đầu tư</span>
      </NavLink>
      <NavLink
        to={'/account'}
        className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center"
      >
        <img width={20} src={toi} />
        <span>Người dùng</span>
      </NavLink>
      {/* <NavLink to={'/message'} className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center">
            <CskhIcon style={{ fill: '#fff' }} />
            <span>CSKH</span>
        </NavLink> */}
    </div>
  )
}

export default MenuBottom
