import { SVGProps } from 'react';
const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || 20}
    height={props?.height || 20}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99984 4.16675V15.8334M4.1665 10.0001H15.8332"
      stroke={props?.stroke}
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PlusIcon;
